import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  IconButton,
  Link,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { FaBars } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';

import DrawerComponent from './DrawerComponent';

const Nav = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const bg = 'gray.800';
  const borderColor = 'gray.700';
  const linkColor = 'gray.300';
  const linkHoverColor = 'white';

  const menuItems = [
    { name: 'Home', path: '/' },
    { name: 'About Us', path: '/about-us' },
    { name: 'Case Studies', path: '/case-studies' },
  ];

  return (
    <Box
      as="nav"
      position="sticky"
      top={0}
      zIndex="sticky"
    >
      <Box
        px={4}
        boxShadow="sm"
        bg={bg}
        borderBottom="1px"
        borderColor={borderColor}
      >
        <Flex
          h={16}
          alignItems="center"
          justifyContent="space-between"
          maxW="5xl"
          mx="auto"
        >
          {/* Logo */}
          <Link
            as={RouterLink}
            to={'/'}
          >
            <HStack>
              <Box>
                <img
                  src="/logos/logo.svg"
                  alt="Tenex Logo"
                  style={{ height: '50px', width: 'auto' }}
                />
              </Box>
              <Text fontSize='2xl' fontWeight='700' color='white'>Tenex Software Club</Text>
            </HStack>
          </Link>

          {/* Navigation Links - Center */}
          <HStack spacing={8} display={{ base: 'none', md: 'flex' }}>
            {menuItems.map((item) => (
              <Link
                key={item.path}
                as={RouterLink}
                to={item.path}
                color={linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}
              >
                {item.name}
              </Link>
            ))}
          </HStack>

          {/* Right Side - Contact Button and Mobile Menu */}
          <HStack spacing={4}>
            <Button
              as={RouterLink}
              to="/contact"
              display={{ base: 'none', md: 'inline-flex' }}
              fontSize={'sm'}
              fontWeight={600}
              color={'white'}
              bg={'teal.400'}
              _hover={{
                bg: 'teal.300',
              }}>
              Contact Us
            </Button>

            <IconButton
              display={{ base: 'flex', md: 'none' }}
              onClick={onOpen}
              icon={<FaBars />}
              variant="ghost"
              aria-label="Toggle navigation"
              color={linkColor}
              _hover={{
                color: linkHoverColor,
              }}
            />
          </HStack>
        </Flex>
      </Box>

      <DrawerComponent
        isOpen={isOpen}
        onClose={onClose}
      />
    </Box>
  );
};

export default Nav;
