import {
  Link as ChakraLink,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const MenuItem = ({ to, children }) => {
  const bgHover = useColorModeValue('gray.100', 'gray.700');

  return (
    <ChakraLink
      as={RouterLink}
      to={to}
      py={2}
      px={4}
      rounded="md"
      _hover={{
        textDecoration: 'none',
        bg: bgHover,
      }}
      display="block"
      w="full"
    >
      <Text fontSize="lg">{children}</Text>
    </ChakraLink>
  );
};

const DrawerComponent = ({ isOpen, onClose, btnRef }) => {
  const bg = useColorModeValue('white', 'gray.800');

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent bg={bg}>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">Menu</DrawerHeader>
        <DrawerBody py={4}>
          <VStack spacing={3} align="stretch">
            <MenuItem to="/">Home</MenuItem>
            <MenuItem to="/about-us">About Us</MenuItem>
            <MenuItem to="/case-studies">Case Studies</MenuItem>
            <MenuItem to="/contact">Contact Us</MenuItem>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default DrawerComponent;