const caseStudiesData = [
  {
    id: 1,
    title: 'AI-Powered Travel Platform',
    shortDescription: 'Revolutionizing travel planning with AI and scalable technology',
    description: 'An early stage travel tech startup approached us to build a seamless, user-friendly platform that would redefine how travelers plan their trips. We delivered a sophisticated AI-driven solution that combines personalized recommendations with intuitive design.',
    category: 'artificial intelligence',
    technologies: ['React', 'Node.js', 'MongoDB', 'Google DialogFlow', 'AWS'],
    gradientStart: '#2C3E50',
    gradientEnd: '#3498DB',
    image: '/case_studies/TA/664459a07a262b5e832eb6d6_TA_featured (1).png',
    challenges: [
      'Dynamic Requirements: The need to provide highly personalized travel recommendations required a flexible and adaptable backend.',
      'User Engagement: Ensuring an engaging user experience across both web and mobile platforms was critical.',
      'AI Implementation: Developing an intelligent chatbot that could accurately interpret user intent and deliver relevant suggestions in real-time.',
      'Scalability: The platform needed to handle a significant amount of traffic during peak times.',
    ],
    solution: [
      'Developed a robust backend using Node.js and MongoDB to manage user data.',
      'Implemented a user-friendly frontend using React.',
      'Created an AI chatbot using Google DialogFlow.',
    ],
    results: [
      'Increased Efficiency: The AI chatbot reduced manual query handling by 70%.',
      'User Growth: The platform saw a 150% increase in user engagement within the first six months of launch.',
      'Scalability Achieved: The system efficiently managed a 300% surge in traffic during the holiday season without any downtime.',
    ],
    testimonial: {
      quote: "Tenex's expertise in AI and scalable architecture helped us create a revolutionary travel platform that our users love.",
      author: "Client CTO"
    }
  },
  {
    id: 2,
    title: 'Healthcare Management System Modernization',
    shortDescription: 'Transforming legacy healthcare software into a modern, scalable platform',
    description: 'We helped a leading provider of cloud-based software solutions for sleep medicine modernize their legacy system into a contemporary, browser-based platform while maintaining strict healthcare compliance standards.',
    category: 'healthcare',
    technologies: ['React', 'Node.js', 'PostgreSQL', 'Docker', 'AWS'],
    gradientStart: '#16A085',
    gradientEnd: '#2ECC71',
    image: '/case_studies/Somnoware/Somnoware-Diagnostics.png',
    challenges: [
      'Legacy System Constraints: The existing system was outdated, limiting scalability and integration capabilities.',
      'Tight Deadline: The project required rapid execution to minimize downtime and disruption.',
      'Comprehensive Migration: The entire product needed to be migrated to align with other products in the suite.',
      'Compliance: Maintaining HIPAA compliance throughout the modernization process.',
    ],
    solution: [
      'Conducted thorough analysis of the legacy system to identify core functionalities.',
      'Developed a scalable, modular architecture using modern web technologies.',
      'Implemented RESTful APIs for efficient data exchange.',
      'Ensured seamless integration with existing healthcare systems.',
    ],
    results: [
      'Enhanced Performance: Significant improvements in speed and reliability.',
      'Improved User Experience: Modern interface with intuitive navigation.',
      'Seamless Integration: Successfully aligned with the client\'s product suite.',
      'Compliance Maintained: Met all healthcare industry standards.',
    ],
    testimonial: {
      quote: "The modernized platform has transformed our operations, making us more efficient while improving patient care.",
      author: "Director of Technology"
    }
  }
 /* {
    id: 3,
    title: 'Fintech Payment Processing System',
    shortDescription: 'Building a secure and scalable payment infrastructure',
    description: 'Developed a robust payment processing system for a fintech startup, focusing on security, scalability, and real-time transaction processing.',
    category: 'fintech',
    technologies: ['React', 'Go', 'PostgreSQL', 'Docker', 'Kubernetes'],
    gradientStart: '#E74C3C',
    gradientEnd: '#C0392B',
    image: '/case_studies/payment-system.jpg',
    challenges: [
      'Security Requirements: Implementing bank-grade security measures.',
      'Real-time Processing: Handling thousands of concurrent transactions.',
      'Regulatory Compliance: Meeting financial industry standards.',
    ],
    solution: [
      'Built a microservices architecture using Go for high performance.',
      'Implemented end-to-end encryption and security measures.',
      'Created a scalable infrastructure using Kubernetes.',
    ],
    results: [
      'Processing millions of transactions daily with 99.99% uptime.',
      'Achieved PCI DSS compliance certification.',
      'Reduced transaction processing time by 60%.',
    ],
    testimonial: {
      quote: "Tenex delivered a payment system that exceeded our expectations in terms of security and performance.",
      author: "VP of Engineering"
    }
  }*/
];

export default caseStudiesData;
