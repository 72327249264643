// This file is now renamed to Home.js to serve as the homepage.

import {
  Box,
  Container,
  Heading,
  Icon,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React from 'react';
import {
  FaCode,
  FaMobileAlt,
  FaRocket,
  FaShieldAlt,
  FaTools,
  FaUserFriends,
} from 'react-icons/fa';

const Feature = ({ title, text, icon, index }) => {
  const bg = 'white';
  const iconBg = 'teal.500';
  const textColor = 'gray.600';
  const headingColor = 'gray.900';

  const MotionBox = motion(Box);

  return (
    <MotionBox
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
    >
      <Stack
        bg={bg}
        boxShadow="lg"
        rounded="xl"
        p={6}
        spacing={4}
        _hover={{
          transform: 'translateY(-5px)',
          boxShadow: 'xl',
          transition: 'all 0.3s ease',
        }}
      >
        <Box
          w={16}
          h={16}
          display="flex"
          alignItems="center"
          justifyContent="center"
          rounded="lg"
          bg={iconBg}
          color="white"
          mb={1}
        >
          <Icon as={icon} w={8} h={8} />
        </Box>
        <Box>
          <Text
            fontWeight="bold"
            fontSize="xl"
            mb={2}
            color={headingColor}
          >
            {title}
          </Text>
          <Text color={textColor}>
            {text}
          </Text>
        </Box>
      </Stack>
    </MotionBox>
  );
};

const Home = () => {
  const bg = 'gray.800';
  const headingColor = 'white';
  const accentColor = 'teal.200';
  const textColor = 'gray.300';

  const features = [
    {
      icon: FaCode,
      title: 'Custom Development',
      text: 'Build scalable, high-performance applications tailored to your unique business needs.',
    },
    {
      icon: FaMobileAlt,
      title: 'Mobile App Development',
      text: 'Create engaging mobile experiences with native and cross-platform solutions.',
    },
    {
      icon: FaRocket,
      title: 'MVP Development',
      text: 'Launch your product quickly with a focused, feature-rich minimum viable product.',
    },
    {
      icon: FaShieldAlt,
      title: 'Security & Compliance',
      text: 'Ensure your applications meet industry standards and security requirements.',
    },
    {
      icon: FaTools,
      title: 'DevOps & Infrastructure',
      text: 'Optimize your development pipeline and cloud infrastructure for maximum efficiency.',
    },
    {
      icon: FaUserFriends,
      title: 'Technical Consulting',
      text: 'Get expert guidance on technology strategy and architecture decisions.',
    },
  ];

  return (
    <Box bg={bg} py={20}>
      <Container maxW="6xl">
        <VStack spacing={12}>
          <Box textAlign="center">
            <Text
              color={accentColor}
              fontWeight="semibold"
              fontSize="sm"
              textTransform="uppercase"
              letterSpacing="wide"
            >
              Our Services
            </Text>
            <Heading
              as="h2"
              size="xl"
              mt={2}
              mb={4}
              color={headingColor}
            >
              Comprehensive Software Solutions
            </Heading>
            <Text
              color={textColor}
              fontSize="lg"
              maxW="container.md"
              mx="auto"
            >
              We offer end-to-end software development services to help you build,
              scale, and maintain your digital products.
            </Text>
          </Box>

          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3 }}
            spacing={10}
            px={{ base: 4, md: 10 }}
          >
            {features.map((feature, index) => (
              <Feature
                key={index}
                icon={feature.icon}
                title={feature.title}
                text={feature.text}
                index={index}
              />
            ))}
          </SimpleGrid>
        </VStack>
      </Container>
    </Box>
  );
};

export default Home;