import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import React, { useRef } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import AboutUs from './components/AboutUs';
import CaseStudies from './components/CaseStudies/CaseStudies';
import CaseStudyDetail from './components/CaseStudies/CaseStudyDetail';
import DrawerComponent from './components/DrawerComponent';
import Footer from './components/Footer';
import ContactForm from './components/HomePage/ContactForm';
import Features from './components/HomePage/Features';
import Hero from './components/HomePage/Hero';
import Nav from './components/Nav';
import PrivacyPolicy from './components/PrivacyPolicy';

const Home = () => (
  <>
    <Hero />
    <Features />
    <ContactForm />
  </>
);

function App() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  return (
    <Router>
      <Flex direction="column" minH="100vh">
        <Box flex="1">
          <Nav ref={btnRef} onOpen={onOpen} />
          <Switch>
            <Route path="/case-studies/:id" component={CaseStudyDetail} />
            <Route path="/case-studies" component={CaseStudies} />
            {/* <Route path="/testimonials" component={TestimonialsPage} /> */}
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/about-us" component={AboutUs} />
            <Route path="/contact" component={ContactForm} />
            <Route path="/" component={Home} />
          </Switch>
        </Box>
        <Footer />
        <DrawerComponent isOpen={isOpen} onClose={onClose} btnRef={btnRef} />
      </Flex>
    </Router>
  );
}

export default App;