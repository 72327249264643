import {
  Box,
  HStack,
  Icon,
  Link,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import {
  FaEnvelope,
  FaLinkedin,
  FaMapMarkerAlt,
} from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';

const Footer = () => {
  const bg = 'gray.900';
  const borderColor = 'gray.700';
  const textColor = 'gray.400';
  const headingColor = 'white';
  const linkColor = 'gray.400';
  const linkHoverColor = 'white';

  const links = {
    company: [
      { name: 'About Us', path: '/about-us' },
      { name: 'Case Studies', path: '/case-studies' },
      { name: 'Contact', path: '/contact' },
    ],
    services: [
      'Custom Software Development',
      'Mobile App Development',
      'MVP Development',
      'Technical Consulting',
    ],
    social: [
      {
        name: 'LinkedIn',
        icon: FaLinkedin,
        url: 'https://www.linkedin.com/company/tenex-software',
      },
    ],
  };

  return (
    <Box bg={bg} color={textColor}>
      <Box maxW="7xl" mx="auto" py={10} px={{ base: 5, md: 10 }}>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={8} mb={8}>
          {/* Company Info */}
          <Stack spacing={6}>
            <Box>
              <img
                src="/logos/logo.svg"
                alt="Tenex Logo"
                style={{ height: '60px', width: 'auto' }}
              />
              <Text mt={4}>
                Building innovative software solutions for forward-thinking businesses.
              </Text>
            </Box>
            <Stack spacing={4}>
              <HStack>
                <Icon as={FaMapMarkerAlt} />
                <Text>Bengaluru, India</Text>
              </HStack>
              <Link href="mailto:contact@tenex.club" _hover={{ color: linkHoverColor }}>
                <HStack>
                  <Icon as={FaEnvelope} />
                  <Text>contact@tenex.club</Text>
                </HStack>
              </Link>
            </Stack>
          </Stack>

          {/* Links */}
          <Stack align={'flex-start'}>
            <Text fontWeight="500" fontSize="lg" mb={2} color={headingColor}>
              Company
            </Text>
            <Stack spacing={3}>
              {links.company.map((link) => (
                <Link
                  key={link.name}
                  as={RouterLink}
                  to={link.path}
                  color={linkColor}
                  _hover={{ color: linkHoverColor }}
                >
                  {link.name}
                </Link>
              ))}
            </Stack>
          </Stack>

          <Stack align={'flex-start'}>
            <Text fontWeight="500" fontSize="lg" mb={2} color={headingColor}>
              Services
            </Text>
            <Stack spacing={3}>
              {links.services.map((service) => (
                <Text key={service}>{service}</Text>
              ))}
            </Stack>
          </Stack>

          <Stack align={'flex-start'}>
            <Text fontWeight="500" fontSize="lg" mb={2} color={headingColor}>
              Connect With Us
            </Text>
            <Stack spacing={3}>
              {links.social.map((social) => (
                <Link
                  key={social.name}
                  href={social.url}
                  isExternal
                  color={linkColor}
                  _hover={{ color: linkHoverColor }}
                >
                  <HStack>
                    <Icon as={social.icon} />
                    <Text>{social.name}</Text>
                  </HStack>
                </Link>
              ))}
              <Link
                as={RouterLink}
                to="/contact"
                color={linkColor}
                _hover={{ color: linkHoverColor }}
              >
                <HStack>
                  <Icon as={FaEnvelope} />
                  <Text>Contact Form</Text>
                </HStack>
              </Link>
            </Stack>
          </Stack>
        </SimpleGrid>

        <Box
          pt={8}
          mt={8}
          borderTopWidth={1}
          borderColor={borderColor}
          textAlign="center"
        >
          <Text fontSize="sm">
            &copy; {new Date().getFullYear()} Tenex Software. All rights reserved
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
