import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Container,
  Heading,
  Image,
  Stack,
  Tag,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import caseStudiesData from '../../data/caseStudiesData';

export default function CaseStudyDetail() {
  const { id } = useParams();
  const history = useHistory();
  const caseStudy = caseStudiesData.find(cs => cs.id === parseInt(id));

  const bg = 'gray.900';
  const cardBg = 'gray.800';
  const headingColor = 'white';
  const textColor = 'gray.300';
  const borderColor = 'gray.700';

  if (!caseStudy) {
    history.push('/case-studies');
    return null;
  }

  return (
    <Box bg={bg} minH="100vh" py={20}>
      <Container maxW="7xl">
        <Stack spacing={8}>
          <Button
            leftIcon={<ArrowBackIcon />}
            onClick={() => history.push('/case-studies')}
            variant="ghost"
            color={textColor}
            _hover={{ bg: 'gray.800' }}
          >
            Back to Case Studies
          </Button>

          <Box
            bg={cardBg}
            rounded="xl"
            p={8}
            shadow="lg"
            borderWidth="1px"
            borderColor={borderColor}
          >
            <Stack spacing={6}>
              <Heading color={headingColor} size="2xl">
                {caseStudy.title}
              </Heading>

              <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
                {caseStudy.technologies.map((tech) => (
                  <Tag
                    key={tech}
                    size="lg"
                    variant="subtle"
                    colorScheme="teal"
                  >
                    {tech}
                  </Tag>
                ))}
              </Stack>

              <Box position="relative" h={{ base: '200px', md: '400px' }}>
                <Image
                  src={caseStudy.image}
                  alt={caseStudy.title}
                  objectFit="cover"
                  w="full"
                  h="full"
                  rounded="lg"
                />
              </Box>

              <Stack spacing={6}>
                <Stack spacing={4}>
                  <Heading color={headingColor} size="lg">
                    Overview
                  </Heading>
                  <Text color={textColor} fontSize="lg">
                    {caseStudy.description}
                  </Text>
                </Stack>

                <Stack spacing={4}>
                  <Heading color={headingColor} size="lg">
                    Challenges
                  </Heading>
                  {caseStudy.challenges.map((challenge, index) => (
                    <Text key={index} color={textColor} fontSize="lg">
                      {challenge}
                    </Text>
                  ))}
                </Stack>

                <Stack spacing={4}>
                  <Heading color={headingColor} size="lg">
                    Our Solution
                  </Heading>
                  {caseStudy.solution.map((solution, index) => (
                    <Text key={index} color={textColor} fontSize="lg">
                      {solution}
                    </Text>
                  ))}
                </Stack>

                <Stack spacing={4}>
                  <Heading color={headingColor} size="lg">
                    Results
                  </Heading>
                  {caseStudy.results.map((result, index) => (
                    <Text key={index} color={textColor} fontSize="lg">
                      {result}
                    </Text>
                  ))}
                </Stack>
              </Stack>
            </Stack>
          </Box>
          {caseStudy.conclusion && caseStudy.conclusion.length > 0 ? (
            <Box p={4} mb={4}>
              {caseStudy.conclusion.map((conclusion, index) => (
                <Text key={index} mb={2} color={textColor}>
                  {conclusion}
                </Text>
              ))}
            </Box>
          ) : null}
          <Text fontWeight="bold" color={textColor}>Want to transform your innovative idea into a success story? <a textDecoration='underline !important' href="https://www.tenex.club">Contact Us</a> today to start building your dream product.</Text>
        </Stack>
      </Container>
    </Box>
  );
}
