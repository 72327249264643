import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';

const ContactForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData(e.target);
    const data = {
      timestamp: new Date().toISOString(),
      name: formData.get('name'),
      email: formData.get('email'),
      company: formData.get('company'),
      message: formData.get('message'),
    };

    console.log('Submitting form data:', data);

    try {
      const response = await fetch('https://script.google.com/macros/s/AKfycbw5aSm_M8s4KvWv2iJgO7Ki_M6fb_nVCoSjAQJPaohxynov_uxwFr9dbrXPwnfmdAx0tg/exec', {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      console.log('Form submission response:', response);

      // Clear the form
      e.target.reset();

      toast({
        title: 'Message sent!',
        description: "We'll get back to you as soon as possible.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Form submission error:', error);

      toast({
        title: 'Error sending message',
        description: 'Please try again later or email us directly at contact@tenex.club',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box bg="gray.900" color="gray.300" py={16}>
      <Container maxW="4xl">
        <Stack spacing={8} align="center">
          <Stack spacing={2} textAlign="center">
            <Heading color="white" fontSize={{ base: '3xl', md: '4xl' }}>
              Get in Touch
            </Heading>
            <Text fontSize={{ base: 'lg', md: 'xl' }} color="gray.400">
              Have a project in mind? Let's discuss how we can help.
            </Text>
          </Stack>

          <Box
            as="form"
            onSubmit={handleSubmit}
            w="100%"
            p={8}
            bg="gray.800"
            rounded="xl"
            shadow="lg"
          >
            <Stack spacing={6}>
              <FormControl isRequired>
                <FormLabel>Name</FormLabel>
                <Input
                  name="name"
                  placeholder="Your name"
                  bg="gray.700"
                  border={0}
                  _focus={{
                    bg: 'gray.600',
                    outline: 'none',
                  }}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  name="email"
                  type="email"
                  placeholder="your@email.com"
                  bg="gray.700"
                  border={0}
                  _focus={{
                    bg: 'gray.600',
                    outline: 'none',
                  }}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Company</FormLabel>
                <Input
                  name="company"
                  placeholder="Your company"
                  bg="gray.700"
                  border={0}
                  _focus={{
                    bg: 'gray.600',
                    outline: 'none',
                  }}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Message</FormLabel>
                <Textarea
                  name="message"
                  placeholder="Tell us about your project..."
                  bg="gray.700"
                  border={0}
                  _focus={{
                    bg: 'gray.600',
                    outline: 'none',
                  }}
                  rows={6}
                />
              </FormControl>

              <Button
                type="submit"
                colorScheme="teal"
                size="lg"
                fontSize="md"
                isLoading={isSubmitting}
              >
                Send Message
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default ContactForm;
