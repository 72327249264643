import { 
  Box, 
  Button, 
  Container, 
  Heading, 
  HStack, 
  Icon, 
  SimpleGrid, 
  Stack, 
  Tag, 
  Text, 
  VStack,
  useColorModeValue 
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';

import caseStudiesData from '../../data/caseStudiesData';

const CaseStudyCard = ({ caseStudy, index }) => {
  const bg = 'gray.800';
  const textColor = 'gray.300';
  
  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { delay: index * 0.1 }
    }
  };

  return (
    <Box
      as={motion.div}
      variants={cardVariants}
      initial="hidden"
      animate="visible"
      borderRadius="xl"
      overflow="hidden"
      bg={bg}
      boxShadow="xl"
      _hover={{
        transform: 'translateY(-5px)',
        boxShadow: '2xl',
        transition: 'all 0.3s ease'
      }}
    >
      <Box
        h="200px"
        bg={`linear-gradient(135deg, ${caseStudy.gradientStart} 0%, ${caseStudy.gradientEnd} 100%)`}
        position="relative"
        p={6}
      >
        <Heading
          as="h3"
          size="lg"
          color="white"
          position="absolute"
          bottom={6}
          left={6}
        >
          {caseStudy.title}
        </Heading>
      </Box>
      
      <VStack align="stretch" p={6} spacing={4}>
        <Text color={textColor} noOfLines={3}>
          {caseStudy.description}
        </Text>
        
        <HStack spacing={2} flexWrap="wrap">
          {caseStudy.technologies.map((tech, idx) => (
            <Tag
              key={idx}
              size="sm"
              variant="subtle"
              colorScheme="teal"
            >
              {tech}
            </Tag>
          ))}
        </HStack>

        <Button
          as={Link}
          to={`/case-studies/${caseStudy.id}`}
          variant="ghost"
          colorScheme="teal"
          size="sm"
          rightIcon={<Icon as={FaArrowRight} />}
          alignSelf="flex-start"
        >
          Read Case Study
        </Button>
      </VStack>
    </Box>
  );
};

const CaseStudies = () => {
  const [filter, setFilter] = useState('all');
  const bg = 'gray.900';
  const headingColor = 'white';
  const accentColor = 'teal.400';
  const textColor = 'gray.300';
  
  const categories = ['all', ...new Set(caseStudiesData.flatMap(cs => cs.category))];
  
  const filteredCaseStudies = caseStudiesData.filter(cs => 
    filter === 'all' || cs.category === filter
  );

  return (
    <Box 
      bg={bg}
      py={20}
    >
      <Container maxW="6xl">
        <VStack spacing={12}>
          <Box textAlign="center">
            <Text
              color={accentColor}
              fontWeight="semibold"
              fontSize="sm"
              textTransform="uppercase"
              letterSpacing="wide"
            >
              Our Work
            </Text>
            <Heading
              as="h1"
              size="xl"
              mt={2}
              mb={4}
              color={headingColor}
            >
              Case Studies
            </Heading>
            <Text
              color={textColor}
              fontSize="lg"
              maxW="container.md"
              mx="auto"
            >
              Explore our portfolio of successful projects and digital transformations
            </Text>
          </Box>

          <HStack spacing={4} wrap="wrap" justify="center">
            {categories.map((category) => (
              <Button
                key={category}
                size="sm"
                variant={filter === category ? 'solid' : 'outline'}
                colorScheme="teal"
                onClick={() => setFilter(category)}
                textTransform="capitalize"
              >
                {category}
              </Button>
            ))}
          </HStack>

          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3 }}
            spacing={8}
            w="full"
          >
            {filteredCaseStudies.map((caseStudy, index) => (
              <CaseStudyCard
                key={caseStudy.id}
                caseStudy={caseStudy}
                index={index}
              />
            ))}
          </SimpleGrid>
        </VStack>
      </Container>
    </Box>
  );
};

export default CaseStudies;
