import { Box, Container, Heading, Text } from '@chakra-ui/react';
import React from 'react';

const PrivacyPolicy = () => {
  return (
    <Container maxW="3xl" py={12}>
      <Box>
        <Heading as="h1" size="xl" textAlign="center" mb={4}>
          Privacy Policy
        </Heading>

        <Text fontSize="lg" mb={2}>
          <strong>Effective Date:</strong> January 14, 2025
        </Text>

        <Text mb={4}>
          At Tenex Software Club ("we," "our," or "us"), your privacy is of paramount importance.
          This Privacy Policy outlines how we collect, use, and protect your information when you
          visit our website (www.tenex.club) and use our services.
        </Text>

        <Heading as="h2" size="lg" mb={2}>
          1. Information We Collect
        </Heading>

        <Text fontSize="md" mb={2}>
          1.1 Personal Information
        </Text>
        <ul>
          <Text as="li">Name</Text>
          <Text as="li">Email address</Text>
          <Text as="li">Phone number</Text>
          <Text as="li">Company name</Text>
          <Text as="li">Any other information you provide voluntarily through contact forms or communications.</Text>
        </ul>

        <Text fontSize="md" mb={2}>
          1.2 Non-Personal Information
        </Text>
        <ul>
          <Text as="li">Browser type</Text>
          <Text as="li">IP address</Text>
          <Text as="li">Device information</Text>
          <Text as="li">Cookies and usage data (e.g., pages visited, time spent on the site)</Text>
        </ul>

        <Heading as="h2" size="lg" mb={2}>
          2. How We Use Your Information
        </Heading>
        <Text>We use the collected information to:</Text>
        <ul>
          <Text as="li">Provide and improve our services</Text>
          <Text as="li">Respond to inquiries or requests</Text>
          <Text as="li">Personalize your experience on our website</Text>
          <Text as="li">Communicate updates, offers, or newsletters (with your consent)</Text>
          <Text as="li">Analyze website performance and usage trends</Text>
        </ul>

        <Heading as="h2" size="lg" mb={2}>
          3. Sharing Your Information
        </Heading>
        <Text mb={4}>
          We do not sell, rent, or trade your personal information. However, we may share your data with:
        </Text>
        <ul>
          <Text as="li"><strong>Service Providers:</strong> Third-party vendors assisting with website functionality, hosting, or analytics.</Text>
          <Text as="li"><strong>Legal Obligations:</strong> Authorities if required by law or to protect our legal rights.</Text>
        </ul>

        <Heading as="h2" size="lg" mb={2}>
          4. Cookies and Tracking Technologies
        </Heading>
        <Text mb={4}>
          We use cookies and similar technologies to enhance your browsing experience. You can modify your browser
          settings to disable cookies, but some site features may not function correctly as a result.
        </Text>

        <Heading as="h2" size="lg" mb={2}>
          5. Data Security
        </Heading>
        <Text mb={4}>
          We implement industry-standard security measures to protect your personal information. While we strive
          to safeguard your data, no method of transmission or storage is 100% secure, and we cannot guarantee
          absolute security.
        </Text>

        <Heading as="h2" size="lg" mb={2}>
          6. Your Rights
        </Heading>
        <Text mb={4}>
          Depending on your location, you may have rights regarding your personal information, including:
        </Text>
        <ul>
          <Text as="li">Accessing, correcting, or deleting your data</Text>
          <Text as="li">Withdrawing consent for data processing</Text>
          <Text as="li">Opting out of marketing communications</Text>
        </ul>
        <Text mb={4}>
          To exercise these rights, contact us at hello@tenex.club.
        </Text>

        <Heading as="h2" size="lg" mb={2}>
          7. Third-Party Links
        </Heading>
        <Text mb={4}>
          Our website may contain links to third-party sites. We are not responsible for their privacy practices.
          Please review their policies independently.
        </Text>

        <Heading as="h2" size="lg" mb={2}>
          8. Changes to This Privacy Policy
        </Heading>
        <Text mb={4}>
          We may update this policy periodically. Changes will be reflected on this page with a revised effective date.
          We encourage you to review it regularly.
        </Text>

        <Heading as="h2" size="lg" mb={2}>
          9. Contact Us
        </Heading>
        <Text mb={4}>
          For questions or concerns about this Privacy Policy, contact us at:
        </Text>
        <Text>
          <strong>Email:</strong> hello@tenex.club<br />
          <strong>Address:</strong> San Francisco, CA
        </Text>

        <Text mb={4} sx={{ mt: 2 }}>
          Your trust is important to us, and we're committed to protecting your privacy as you explore what
          Tenex Software Club has to offer.
        </Text>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
