import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Icon,
  Image,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';

const Hero = () => {
  const MotionBox = motion(Box);
  const MotionFlex = motion(Flex);

  const bg = 'gray.900';
  const textColor = 'gray.300';
  const headingColor = 'white';
  const accentColor = 'teal.400';
  const buttonBg = 'teal.400';
  const buttonHoverBg = 'teal.500';

  const isDesktop = useBreakpointValue({ base: false, lg: true });

  return (
    <Box bg={bg} position="relative" overflow="hidden">
      {/* Background Gradient */}
      <Box
        position="absolute"
        top="-10%"
        right="-10%"
        width="50%"
        height="120%"
        bg={`linear-gradient(45deg, ${accentColor} 0%, transparent 70%)`}
        opacity="0.1"
        transform="rotate(-12deg)"
        zIndex="0"
      />

      <Container maxW="7xl" py={{ base: 20, md: 28 }}>
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          spacing={{ base: 10, lg: 20 }}
          align="center"
          position="relative"
          zIndex="1"
        >
          {/* Left Content */}
          <Stack flex={1} spacing={8}>
            <MotionBox
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Text
                color={accentColor}
                fontWeight="semibold"
                fontSize="lg"
                textTransform="uppercase"
                letterSpacing="wide"
                mb={4}
              >
                Software Development Agency
              </Text>
              <Heading
                as="h1"
                fontSize={{ base: '4xl', md: '5xl', lg: '6xl' }}
                fontWeight="bold"
                lineHeight="shorter"
                color={headingColor}
                mb={6}
              >
                Building{' '}
                <Text
                  as="span"
                  bgGradient={`linear(to-r, ${accentColor}, teal.200)`}
                  bgClip="text"
                >
                  Innovative
                </Text>{' '}
                Software Solutions
              </Heading>
              <Text
                fontSize={{ base: 'lg', md: 'xl' }}
                color={textColor}
                maxW="xl"
                lineHeight="tall"
              >
                We transform your ideas into powerful digital solutions. From custom software
                to mobile apps, we build scalable products that drive business growth.
              </Text>
            </MotionBox>

            <MotionFlex
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3, duration: 0.5 }}
              gap={4}
              direction={{ base: 'column', sm: 'row' }}
            >
              <Button
                as={RouterLink}
                to="/case-studies"
                size="lg"
                bg={buttonBg}
                color="white"
                px={8}
                _hover={{
                  bg: buttonHoverBg,
                  transform: 'translateY(-2px)',
                  boxShadow: 'lg',
                }}
                rightIcon={<Icon as={FaArrowRight} />}
              >
                View Our Work
              </Button>
              <Button
                as={RouterLink}
                to="/contact"
                size="lg"
                variant="outline"
                colorScheme="teal"
                px={8}
                _hover={{
                  transform: 'translateY(-2px)',
                  boxShadow: 'lg',
                }}
              >
                Contact Us
              </Button>
            </MotionFlex>

            {/* Stats Section */}
            <MotionFlex
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5, duration: 0.5 }}
              wrap="wrap"
              gap={8}
              mt={8}
            >
              {[
                { label: 'Projects Completed', value: '10+' },
                { label: 'Client Satisfaction', value: '100%' },
                { label: 'Years Experience', value: '2+' },
              ].map((stat, index) => (
                <Box key={index} minW="120px">
                  <Text
                    fontSize="3xl"
                    fontWeight="bold"
                    color={accentColor}
                    lineHeight="tight"
                  >
                    {stat.value}
                  </Text>
                  <Text fontSize="sm" color={textColor}>
                    {stat.label}
                  </Text>
                </Box>
              ))}
            </MotionFlex>
          </Stack>

          {/* Right Image/Animation */}
          {isDesktop && (
            <MotionBox
              flex={1}
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2, duration: 0.5 }}
            >
              <Image
                src="/HeroImg.jpeg"
                alt="Software Development"
                w="full"
                h="auto"
                maxH="600px"
                objectFit="contain"
                filter='brightness(0.9)'
              />
            </MotionBox>
          )}
        </Stack>
      </Container>

      {/* Bottom Wave */}
      <Box
        position="absolute"
        bottom="-2px"
        left="0"
        right="0"
        height="150px"
        bg={`linear-gradient(180deg, transparent 0%, ${bg} 100%)`}
        transform="scale(1.1)"
      />
    </Box>
  );
};

export default Hero;