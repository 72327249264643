import {
  Box,
  Button,
  Container,
  HStack,
  Heading,
  Icon,
  Image,
  Link,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  useColorMode,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React from 'react';
import { FaGithub, FaLinkedin } from 'react-icons/fa';

const TeamMemberCard = ({ name, role, image, bio, specialties, linkedin, github }) => {
  const MotionBox = motion(Box);
  const { colorMode } = useColorMode();

  return (
    <MotionBox
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      bg={colorMode === 'light' ? 'white' : 'gray.800'}
      rounded="xl"
      shadow="xl"
      p={6}
      overflow="hidden"
      _hover={{
        transform: 'translateY(-5px)',
        shadow: '2xl',
        transition: 'all 0.3s ease'
      }}
    >
      <VStack spacing={4}>
        <Box
          position="relative"
          w="full"
          h="200px"
          overflow="hidden"
          rounded="lg"
        >
          <Image
            src={image}
            alt={name}
            objectFit="cover"
            w="full"
            h="full"
            transition="0.3s ease"
            _hover={{ transform: 'scale(1.05)' }}
          />
        </Box>

        <VStack spacing={2} align="center">
          <Heading 
            as="h3" 
            size="md" 
            color={colorMode === 'light' ? 'gray.800' : 'white'}
          >
            {name}
          </Heading>
          <Text
            color={colorMode === 'light' ? 'teal.600' : 'teal.200'}
            fontWeight="medium"
            fontSize="sm"
            textTransform="uppercase"
            letterSpacing="wide"
          >
            {role}
          </Text>
        </VStack>

        <Text
          color={colorMode === 'light' ? 'gray.600' : 'gray.300'}
          fontSize="sm"
          textAlign="center"
        >
          {bio}
        </Text>

        <Box w="full">
          <Text
            color={colorMode === 'light' ? 'gray.700' : 'gray.300'}
            fontWeight="semibold"
            mb={2}
          >
            Specialties
          </Text>
          <Box
            p={3}
            bg={colorMode === 'light' ? 'gray.50' : 'gray.700'}
            rounded="lg"
          >
            <Text 
              fontSize="sm" 
              color={colorMode === 'light' ? 'gray.600' : 'gray.300'}
            >
              {specialties}
            </Text>
          </Box>
        </Box>

        <HStack spacing={4}>
          {linkedin && (
            <Link href={linkedin} isExternal>
              <Button
                size="sm"
                colorScheme="linkedin"
                leftIcon={<Icon as={FaLinkedin} />}
              >
                LinkedIn
              </Button>
            </Link>
          )}
          {github && (
            <Link href={github} isExternal>
              <Button
                size="sm"
                colorScheme="gray"
                leftIcon={<Icon as={FaGithub} />}
              >
                GitHub
              </Button>
            </Link>
          )}
        </HStack>
      </VStack>
    </MotionBox>
  );
};

const AboutUs = () => {
  const MotionBox = motion(Box);
  const { colorMode } = useColorMode();
  const bg = 'gray.900';
  const cardBg = 'gray.800';
  const headingColor = 'white';
  const textColor = 'gray.300';
  const borderColor = 'gray.700';
  const accentColor = 'teal.400';

  return (
    <Box bg={bg} minH="100vh">
      <Container maxW="7xl" py={20}>
        <Stack spacing={12}>
          {/* Hero Section */}
          <Stack spacing={4} textAlign="center">
            <Heading color={headingColor} fontSize={{ base: '3xl', md: '4xl' }}>
              Building Tomorrow's Technology Today
            </Heading>
            <Text color={textColor} fontSize={{ base: 'lg', md: 'xl' }} maxW="3xl" mx="auto">
              At Tenex Software Club, we are passionate about transforming innovative ideas into powerful digital solutions.
              Our team of experts combines technical excellence with business acumen to deliver exceptional results for early-stage businesses.
            </Text>
          </Stack>

          {/* Mission & Vision */}
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
            <Box bg={cardBg} p={8} rounded="xl" shadow="lg">
              <Stack spacing={4}>
                <Heading size="lg" color={headingColor}>
                  Our Mission
                </Heading>
                <Text color={textColor}>
                  To empower businesses with cutting-edge software solutions that drive growth,
                  efficiency, and innovation.
                </Text>
              </Stack>
            </Box>
            <Box bg={cardBg} p={8} rounded="xl" shadow="lg">
              <Stack spacing={4}>
                <Heading size="lg" color={headingColor}>
                  Our Vision
                </Heading>
                <Text color={textColor}>
                  To be the leading software development partner for businesses seeking to transform
                  their digital presence and operations.
                </Text>
              </Stack>
            </Box>
          </SimpleGrid>

          {/* Team */}
          <Box>
            <Heading color={headingColor} fontSize="2xl" mb={12} textAlign="center">
              Meet Our Team
            </Heading>
            <SimpleGrid
              columns={{ base: 1, lg: 2 }}
              spacing={10}
              px={{ base: 4, md: 10 }}
            >
              <TeamMemberCard
                name="Hari Subramanian"
                role="Founder and Director"
                image="/team/Hari.jpg"
                bio="Startup founder and experienced technology leader with a strong background in engineering and product management at companies like Uber, Amazon, and VMware. Led high-performing teams, including building Uber's Customer Obsession Engineering org."
                specialties="Cloud migration, machine learning, distributed systems, and scalable architecture. Holder of 14+ patents and published papers."
                linkedin="https://www.linkedin.com/in/harisubramanian/"
              />
              <TeamMemberCard
                name="Aparna Ramakrishnan"
                role="Engineering Leadership"
                image="/team/Aparna.jpg"
                bio="Experienced in leading high-performance engineering teams and building robust mobile and web applications. Passionate about creating scalable and user-centric solutions."
                specialties="React, React Native, Flutter, Next.js, Redux, Node.js, UI frameworks, PWA, Security, Scalability, and Performance optimization."
                linkedin="https://www.linkedin.com/in/aparna-ramakrishnan/"
              />
            </SimpleGrid>
          </Box>

          {/* Values */}
          <Box>
            <Heading color={headingColor} fontSize="2xl" mb={8} textAlign="center">
              Our Values
            </Heading>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8}>
              {[
                {
                  title: 'Innovation',
                  description:
                    'We constantly push boundaries to deliver cutting-edge solutions.',
                },
                {
                  title: 'Quality',
                  description:
                    'We maintain the highest standards in every project we undertake.',
                },
                {
                  title: 'Collaboration',
                  description:
                    'We work closely with our clients to ensure their success.',
                },
              ].map((value, index) => (
                <Box
                  key={index}
                  bg={cardBg}
                  p={6}
                  rounded="lg"
                  shadow="md"
                  borderWidth="1px"
                  borderColor={borderColor}
                >
                  <Stack spacing={4}>
                    <Heading size="md" color={headingColor}>
                      {value.title}
                    </Heading>
                    <Text color={textColor}>{value.description}</Text>
                  </Stack>
                </Box>
              ))}
            </SimpleGrid>
          </Box>

          {/* Call to Action */}
          <Stack spacing={4} textAlign="center" py={8}>
            <Heading color={headingColor} size="lg">
              Ready to Start Your Project?
            </Heading>
            <Text color={textColor} fontSize="lg">
              Let's discuss how we can help bring your ideas to life.
            </Text>
            <Button
              as={Link}
              href="/contact"
              size="lg"
              colorScheme="teal"
              maxW="200px"
              mx="auto"
              mt={4}
            >
              Contact Us
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default AboutUs;
